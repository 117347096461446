import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import IFrameWrapper from "./IFrameWrapper";

const styles = {
  app: {
    height: '100vh',
    margin: 0,
    padding: 0,
  }
};



function App() {
  return (
    <div style={styles.app}>
      <Router>
        <Routes>
          <Route path="/*" element={<IFrameWrapper/>}/>
        </Routes>
      </Router>
    </div>
  );
}

export default App;

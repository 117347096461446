import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const styles = {
  container: {
    width: '100%',
    height: '100vh',
    overflow: 'hidden',
  },
  iframe: {
    width: '100%',
    height: '100%',
    border: 'none',
    display: 'block',
  }
};

const IFrameWrapper = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [iframeUrl, setIframeUrl] = useState('https://scrimmage.co');

  // Function to extract path and query parameters from URL
  const getPathWithQuery = useCallback((url: string) => {
    try {
      console.log(url)
      const urlObj = new URL(url);
      return `${urlObj.pathname}${urlObj.search}${urlObj.hash}`;
    } catch (error) {
      console.error('Error parsing URL:', error);
      return '/';
    }
  }, []);

  // Handle iframe load and URL changes
  const handleIframeLoad = (event: any) => {
    if (!event.target || !event.target.contentWindow) {
      return;
    }
    try {
      const iframe = event.target;
      const iframeWindow = iframe.contentWindow;

      // Create a MutationObserver to watch for URL changes in the iframe
      const observer = new MutationObserver(() => {
        const currentIframePath = getPathWithQuery(iframeWindow.location.href);
        if (currentIframePath !== location.pathname + location.search + location.hash) {
          navigate(currentIframePath);
        }
      });

      // Observe the iframe document for changes
      observer.observe(iframeWindow.document, {
        subtree: true,
        childList: true
      });

      // Clean up observer when iframe reloads
      return () => observer.disconnect();
    } catch (error) {
      console.error('Error handling iframe load:', error);
    }
  };

  // Listen for changes in the iframe's location using postMessage
  useEffect(() => {
    const handleMessage = (event: any) => {
      if (event.origin === 'https://scrimmage.co') {
        try {
          const newPath = getPathWithQuery(event.data.location);
          if (newPath !== location.pathname + location.search + location.hash) {
            navigate(newPath);
          }
        } catch (error) {
          console.error('Error handling postMessage:', error);
        }
      }
    };

    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, [navigate, location, getPathWithQuery]);

  // Update iframe URL when parent app path changes
  useEffect(() => {
    const newUrl = `https://scrimmage.co${location.pathname}${location.search}${location.hash}`;
    setIframeUrl(newUrl);
  }, [location.pathname, location.search, location.hash]);

  return (
    <div style={styles.container}>
      <iframe
        src={iframeUrl}
        style={styles.iframe}
        onLoad={handleIframeLoad}
        title="Scrimmage Content"
      />
    </div>
  );
};

export default IFrameWrapper;